.ingredients-header {
  display: flex;
  justify-content: space-between;
  padding: 30px 30px 0px;
}
.add-ingredient-button {
  border: 1.5px solid #079a91 !important;
  color: #079a91 !important;
}
.add-ingredient-modal-wrapper {
  background-color: white;
  border-radius: 20px !important;
  padding: 55px 55px 65px;
  border: none !important;
  box-shadow: 0px 0px rgba(48, 73, 191, 0.05) !important;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 93vh;
  overflow-y: auto;
  display: block !important;
}

/* .add-ingredient-modal-wrapper::-webkit-scrollbar {
  width: 0px;
} */

.add-ingredient-modal-inputs {
  display: flex;
  gap: 20px;
}
.cards-container {
  padding: 30px 30px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}
.ingredient-card-container {
  position: relative;
  width: 278px;
  height: 270px;
  border: 1px solid #565b60;
}
.ingredient-card-container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.ingredient-card-description {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.18);
  padding: 20px 10px 10px;
  bottom: 0px;
  width: 100%;
}
.ingredient-card-description > h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 14px;
  color: white;
  margin-bottom: 10px;
}
.ingredient-card-description > p {
  color: #fff;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 21px;
  max-height: 48px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  text-align: justify;
  margin-bottom: 10px;
}
.ingredient-card-button {
  background-color: #fff !important;
  color: #079a91 !important;
  padding: 4px 10px !important;
  border-radius: 25px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

/* MODAL */
.modal-left-side,
.modal-right-side {
  gap: 20px;
}
.modal-left-side h5,
.modal-right-side h5 {
  margin-bottom: 10px;
  color: #919eab;
  font-weight: 500;
  font-size: 16px;
}
.images-wrapper {
  display: flex;
  column-gap: 15px !important;
  row-gap: 20px !important;
  margin-bottom: 0px !important;
  flex-wrap: wrap;
}
.delete-image {
  position: absolute !important;
  top: -15px !important;
  right: -15px !important;
}
.delete-image > svg {
  fill: #ff4842;
}
.modal-add-image-container {
  height: 138px;
  width: 138px;
  border-radius: 16px;
  border: 2px dashed #febf49;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 8px;
}
.inner-add-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.inner-add-image-container > input {
  display: none;
}
.modal-add-image-container p {
  font-family: Poppins;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #919eab;
  text-align: center;
  max-width: 76px;
}
.add-ingredient-modal-buttons {
  display: flex;
  gap: 30px;
  margin-top: 30px;
}

.page-settings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px 0px;
}
.page-infos {
  display: flex;
  column-gap: 10px;
  align-items: center;
}
.page-title {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #565b60;
}
.settings-buttons {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.add-element-nutritional-data {
  width: min-content;
  padding: 10px;
  box-shadow: 0px 0px rgba(42, 42, 42, 0.06);
}
.add-element-nutritional-data-bttn {
  margin-bottom: 10px !important;
}
