.tool-card-container {
  width: 200px;
  height: 200px;
  position: relative;
  border-radius: 24px;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06);
  border: 1px solid rgba(42, 42, 42, 0.06);
  transition: transform 1s;
  overflow: hidden;
}
.tool-card-image-container {
  width: 100%;
  height: 100%;
  border-radius: 24px;
}
.tool-card-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: 24px;
}
.tool-card-container:hover .tool-card-image-container img {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1);
}
.tool-card-name {
  position: absolute !important;
  top: 10px;
  right: 10px;
  border-radius: 20px !important;
  cursor: context-menu !important;
  background: #81c0c7 !important;
}
.tool-card-icons {
  position: absolute;
  bottom: -71px;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 1;
  backdrop-filter: blur(3px);
  box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: bottom 0.5s;
}
.tool-card-container:hover .tool-card-icons {
  bottom: -5px;
}
.add-tool-modal-inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.tool-image-container {
  position: relative;
  height: 180px;
  width: 180px;
  border-radius: 16px;
}
.tool-image-box {
  height: 100%;
  width: 100%;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.tool-image-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.tool-show-more-button-container {
  display: flex;
  justify-content: flex-end;
}
.tool-card-date {
  font-size: 12px;
  color: #357981;
  font-weight: 700;
}
