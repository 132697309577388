.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px 8px;
  border-bottom: 1px solid #81c0c7;
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 3;
}
.navbar-left > h1 {
  color: #febf49;
  text-align: center;
  font-family: "Lobster", cursive;
  font-size: 36px;
  line-height: 28px;
}

.navbar-middle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;
}
.navbar-middle > button {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 700 !important;
  text-align: center;
  font-size: 20px !important;
  line-height: 22px !important;
  color: #565b60 !important;
}
.navber-right {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.navbar-icon {
  color: #161616 !important;
}
