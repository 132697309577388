/* DELETE MODAL */
.delete-modal {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 20px !important;
  padding: 3% 3%;
  border: none !important;
  box-shadow: 0px 0px rgba(48, 73, 191, 0.05) !important;
}
.delete-modal-title {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  /* margin-left: 20px; */
  color: #079a91;
  margin-bottom: 20px;
}
.delete-modal-content {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin-bottom: 40px;
  font-weight: 400;
}

.delete-modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.confirm-delete-button {
  color: #fff !important;
  border: 2px solid #ef2531 !important;
  background-color: #ef2531 !important;
  font-weight: 600 !important;
}
.cancel-delete-button {
  color: #079a91 !important;
  border: 2px solid #079a91 !important;
  font-weight: 600 !important;
}

/* FILTER  */
.filter-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
}
.filter-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.3);
}
.filter-content {
  padding: 50px 32px 30px 24px;
  background: #fff;
  z-index: 5;
  width: 350px;
  height: 100vh;
  position: fixed;
  top: 0px;
  right: -100%;
  transition-property: all;
  transition-duration: 2s;
  transition-timing-function: linear;
  transition-delay: 0s;
}
.filter-content.active {
  right: 0px;
  transition-property: all;
  transition-duration: 2s;
  transition-timing-function: linear;
  transition-delay: 0s;
}
.filter-content-header {
  display: flex;
  align-items: center;
  gap: 20px;
}
.filter-content-header > h1 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  font-size: 28px;
  line-height: 32px;
}
.filter-inputs {
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}
.filter-content.active {
  overflow-y: scroll;
}
/* .filter-content.active::-webkit-scrollbar {
  height: 0;
  width: 0;
} */
.filter-buttons-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}
.apply-filter-button {
  color: #079a91 !important;
  border: 2px solid #079a91 !important;
  font-weight: 600 !important;
}
.reset-button {
  color: #fff !important;
  border: 2px solid #ef2531 !important;
  background-color: #ef2531 !important;
  font-weight: 600 !important;
}
