.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* background: #cde2e5; */
}
.login-container {
  border-radius: 30px;
  display: flex;
  box-shadow: 0px 0px rgba(42, 42, 42, 0.06);
  background: #fff;
  /* border: 2px solid #079a91; */
  border: 2px solid #81c0c7;
  /* border: 2px solid #292d6d; */
}
.login-image-container {
  position: relative;
  border-radius: 30px;
  width: 300px;
}
.login-image-container > img {
  width: 100%;
  height: 100%;
  border-radius: 27px 0px 0px 27px;
  object-position: center;
  object-fit: cover;
}
.login-form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 40px 30px 30px;
}
.login-form > h2 {
  color: #febf49;
  text-align: center;
  font-family: "Lobster", cursive;
  font-size: 36px;
  line-height: 28px;
  margin-bottom: 10px;
}
.login-form > h6 {
  /* color: #81c0c7; */
  /* color: #71bac5; */
  color: #079a91;
  text-align: center;
  font-family: "Lobster", cursive;
  font-size: 28px;
  line-height: 25px;
}
.login-button {
  background-color: #079a91 !important;
  margin: 0px auto !important;
  width: max-content;
  padding: 8px 40px !important;
  border-radius: 25px !important;
  font-size: 18px !important;
}
.login-error-msg {
  font-weight: 700;
  font-size: 12px;
  color: #ef2531;
  margin-top: -12px;
}
