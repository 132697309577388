* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
button {
  text-transform: none !important;
}
ul {
  list-style-type: none;
}
/* show more */
.show-more-container {
  width: 800px;
  margin: auto;
  padding: 50px 0px;
}
.show-more-elements-container {
  border-radius: 20px;
  box-shadow: 0 0 10px rgb(0 0 0 / 25%);
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}
.show-more-element {
  border-bottom: 1px solid #079a91;
  padding-bottom: 10px;
}
.show-more-element > h5 {
  color: #f9b12a;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  margin-bottom: 20px;
}
.show-more-element h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-align: justify;
}
.image-container {
  position: relative;
  height: 138px;
  width: 138px;
  border-radius: 16px;
}
.image-box {
  height: 100%;
  width: 100%;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.image-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.show-more-button-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 20px;
}
.edit-button {
  background-color: #febf49 !important;
  padding: 4px 22px !important;
  border-radius: 25px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  width: max-content !important;
}
.update-status {
  display: flex;
  align-items: center;
  gap: 20px;
}
.confirm-button {
  color: #81c0c7 !important;
  border: 1px solid #81c0c7;
}
.decline-button {
  background-color: #ef2531 !important;
}
.decline-status-reason {
  margin-bottom: 20px;
}
.decline-reason {
  font-size: 18px;
  color: #ef2531;
  font-weight: 700;
  margin-top: 15px;
}
.decline-status-reason > h5 {
  font-size: 16px;
  color: #ef2531;
  font-weight: 700;
  margin-bottom: 15px;
}
.error-msg {
  font-weight: 700;
  font-size: 12px;
  color: #ef2531;
  margin-top: 10px;
}
@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.add-button {
  background-color: #079a91 !important;
  padding: 4px 26px !important;
  border-radius: 25px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  width: max-content !important;
}
.cancel-button {
  background-color: #ef2531 !important;
  padding: 4px 15px !important;
  border-radius: 25px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  width: max-content !important;
}
.nutritional-data-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.nutritional-data-element {
  display: flex;
  width: 90px;
  height: 90px;
  background-color: #b6e2e8;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
  flex-direction: column;
  gap: 10px;
}
.nutritional-data-name {
  font-size: 16px;
  color: #161616;
  font-weight: 500;
  text-align: center;
}
.nutritional-data-value {
  font-size: 16px;
  color: #ef2531;
  font-weight: 600;
  text-align: center;
}
.filter-button {
  background: #f9b12a !important;
  padding: 6px 24px !important;
}

.nutirtional-data-input input {
  padding: 10.5px 14px !important;
}

.nutirtional-data-title,
.ingredients-title {
  margin: 20px 0px 10px;
  color: #919eab;
  font-weight: 500;
  font-size: 16px;
}

/* CARD */
.card-container {
  width: 278px;
  position: relative;
  border-radius: 24px;
  box-shadow: 0px 0px 24px rgba(42, 42, 42, 0.06);
  border: 1px solid rgba(42, 42, 42, 0.06);
}
.card-image-container {
  width: 100%;
  height: 190px;
  border-radius: 24px 24px 0px 0px;
}
.card-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: 24px 24px 0px 0px;
}
.card-status {
  position: absolute !important;
  top: 10px;
  right: 10px;
  border-radius: 20px !important;
  cursor: context-menu !important;
}
.green {
  background: #81c0c7 !important;
}
.red {
  background: #ef2531 !important;
}
.yellow {
  background: #febf49 !important;
}
.card-infos {
  padding: 15px 10px 20px 10px;
  border-top: 1px solid #cde2e5;
}
.card-description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}
.card-date {
  font-size: 12px;
  margin-top: 8px;
  color: #79c9d2;
  font-weight: 700;
}
.card-icons {
  display: flex;
  align-items: center;
}

.video-container {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 16px;
}
.video-box {
  height: 100%;
  width: 100%;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.video-box video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: 16px;
}

.modal-add-video-container {
  height: 200px;
  width: 200px;
  border-radius: 16px;
  border: 2px dashed #febf49;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 8px;
}
.inner-add-video-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.inner-add-image-container > input {
  display: none;
}
.modal-add-video-container p {
  font-family: Poppins;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #919eab;
  text-align: center;
  max-width: 76px;
}

.chips-container {
  margin-top: 15px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.add-step-button {
  background: #079a91 !important;
}
.step-container {
  border: 1px solid #079a91;
  padding: 20px;
  border-radius: 8px;
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.step-number {
  display: flex;
  justify-content: flex-end;
  font-weight: 700;
  font-size: 18px;
  color: #ef2531;
  font-family: "Roboto", sans-serif;
}
.step-infos {
  display: flex;
  justify-content: space-between;
}
.step-infos h5 {
  color: #919eab;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 16px;
  margin: 8px 0px;
}
.step-media {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.step-media h5 {
  color: #919eab;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 8px;
}
.step-products h5 {
  color: #919eab;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 8px;
}
.delete-step-button {
  color: #ef2531 !important;
  border: 1px solid #ef2531 !important;
  font-weight: 600 !important;
}
